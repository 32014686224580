/* eslint-disable react/jsx-props-no-spreading */
import App from 'next/app';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { MuiTheme } from '@eureca/eureca-ui';
import '../assets/css/snackbar-override.css';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider } from 'react-redux';
import { store } from '../redux';
import VLibras from '@djpfs/react-vlibras';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
});

class MyApp extends App {
  componentDidMount() {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <ThemeProvider theme={MuiTheme}>
          <SnackbarProvider
            classes={{
              variantWarning: 'eureca-snack-warning',
            }}
          >
            <CssBaseline />
            <VLibras />
            <main>
              {/* // Disable lint because the next line are HOC component from Next.js */}
              <Provider store={store}>
                <Component {...pageProps} />
              </Provider>
            </main>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default MyApp;
